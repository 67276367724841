import "bootstrap/dist/css/bootstrap.min.css";
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);

const formatMoneyPublic = (value: number): string => {
  // Convert the number to a string and split it into integer and decimal parts
  const [integerPart, decimalPart] = value.toFixed(2).split('.');

  // Add space bars as separators for thousands
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Join the integer and decimal parts with a dot separator
  const formattedValue = `${formattedIntegerPart}.${decimalPart}`;

  return formattedValue;
};

const currency = (value: number): string => {
  // Convert the number to a string
  const stringValue = value.toString();

  // Split the string into integer and decimal parts
  const [integerPart, decimalPart] = stringValue.split('.');

  // Add space bars as separators for thousands
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Join the integer and decimal parts with a dot separator
  const formattedValue = `${formattedIntegerPart}.${decimalPart || '00'}`;

  return formattedValue;
};

app.config.globalProperties.$filters = {

  formatMoneyPublic,
  currency,

  toShotLocalDateTime(date: string) {
    moment.locale("en");
    const m = moment(new Date(date));
    return m.format("YYYY-MM-DD");
  },

  numberToShotLocalDateTime(date: number) {
    moment.locale("en");
    const m = moment.unix(Number(date));
    return m.format("YYYY-MM-DD");
  },
};

app.config.globalProperties.$trim = {
  toLen(text: string, length: number) {
    const trimmedString =
      text.length > length ? text.substring(0, length - 3) + "..." : text;
    return trimmedString;
  },
};

app.use(createPinia()).use(router).mount("#app");

import "bootstrap/dist/js/bootstrap.js";
import moment from "moment";

declare global {
  interface Window {
    ethereum: any;
    provider: any;
  }
}
