import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import { getContract } from "..";
import { BigNumber, Contract, ContractInterface, ethers, Signer } from "ethers";
import { abi } from "./contract.abi";
import { isProxy, toRaw } from 'vue';

export const useVestingStore = defineStore("vestingStore", {
  state: () => ({
    vestingSchedules: Array<VestingSchedule>(),
  }),
  getters: {
    getVestingSchedules: (state) => state.vestingSchedules,
  },
  actions: {
    getContract() : Contract {
      return new ethers.Contract(getContract(), abi, new ethers.providers.Web3Provider(window.ethereum, "any"));
    },
    async getContractWithSigner() : Promise<Contract> {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []); // <- this promps user to connect metamask
      const signer = provider.getSigner();
      return new ethers.Contract(getContract(), abi, signer);
    },
    async subscribe() {
      const authStore = useAuthStore();
      if (!authStore.canExecute) return;
      
      const contract : Contract = this.getContract();
      const releasedFilter = contract.filters.Released(
        null,
        authStore.address,
        null
      );
 
      contract.on(releasedFilter, async () => {
        await this.fetchVestingSchedules();
      });

      const scheduleCreatedFilter = contract.filters.ScheduleCreated(
        authStore.address,
        null
      );

      contract.on(scheduleCreatedFilter, async () => {
        await this.fetchVestingSchedules();
      });

    },
    async claim(vestingScheduleId: string) {
      const authStore = useAuthStore();
      if (!authStore.canExecute) return;

      const contract : Contract = await this.getContractWithSigner();
      const amount = await contract.computeReleasableAmount(vestingScheduleId);
      await contract.release(vestingScheduleId, amount);

    },
 
    async fetchVestingSchedules() {
      const authStore = useAuthStore();
      const contract : Contract = this.getContract();
      
      const vestingScheduleCount = await contract.getVestingSchedulesCountByBeneficiary(authStore.address);
      const vestingSchedules = new Array<VestingSchedule>();
      for (let i = 0; i < vestingScheduleCount.toBigInt(); i++) {
        const vesting = await contract.getVestingScheduleByAddressAndIndex(
          authStore.address,
          i
        );
       
        const months = vesting.duration.toBigInt() / vesting.slicePeriodSeconds.toBigInt();
        const slicePeriodSeconds = Number(vesting.slicePeriodSeconds);
        const start = Number(vesting.start);
        const cliff = Number(vesting.cliff);
        const end = Number((vesting.start.toNumber() + vesting.duration.toNumber()));

        const intentDecimals = 100000000;
        const amountTotal = Number(vesting.amountTotal.toNumber()  / intentDecimals);
        const released = Number(vesting.released.toNumber()  / intentDecimals);
          
        let temp = start + cliff;
        const releaseDates = [];
         for (let m = 0; m < months; m++) {
          releaseDates.push(new Date(Number(temp)));
          temp += slicePeriodSeconds * 1000;
        }
        
        const vestingPlan = await contract.getNextVestingReleaseForScheduleId(vesting.vestingScheduleId);
        const vestingSchedule : VestingSchedule  = {
          vestingScheduleId: vesting.vestingScheduleId,
          index: i,
          beneficiary: vesting.beneficiary,
          start: start,
          end: end,
          amountTotal: amountTotal,
          released: released,
          releaseDates,
          nextClaimDate: Number(vestingPlan.nextClaimDate),
          nextClaimAmount: Number(vestingPlan.nextUnlockedAmount.toNumber()  / intentDecimals),
          releasableAmount: Number(vestingPlan.availableAmount.toNumber() / intentDecimals)
        };
 
        vestingSchedules.push(vestingSchedule);
      }
      this.vestingSchedules = vestingSchedules;
    }
  },
});

export interface VestingSchedule {
  vestingScheduleId: string;
  index: number;
  beneficiary: string;
  amountTotal: number;
  released: number;
  releaseDates: Array<Date>;
  start: number;
  end: number;
  nextClaimDate: number;
  nextClaimAmount: number;
  releasableAmount: number;
}

export interface  VestingPlan {
  beneficiary: string;
  index: number;
  amount: number;
  nextClaimDate: number;
}
