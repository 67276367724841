import { createRouter, createWebHistory } from "vue-router";
import { HomeView, SaleView } from "@/views";
import { useAuthStore } from "@/store";

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes: [
    { path: "/", component: HomeView },
    { path: "/sale", component: SaleView },
  ],
});

router.beforeEach(async (to) => {
  const publicPages = ["/"];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();

  if (authRequired && !authStore.status) {
    authStore.returnUrl = to.fullPath;
    return "/";
  }
});

export default router;
