export function getBaseUrl() {
  const baseUrl = process.env.VUE_APP_BACKEND_URL;
  if (baseUrl === undefined) return "";
  return baseUrl;
}

export function getContract() {
  const contract = process.env.VUE_APP_VESTING_CONTRACT;
  if (contract === undefined) return "";
  return contract;
}

export const Web3Provider = window.ethereum