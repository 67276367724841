import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import { router } from "@/router";
import { connectMetamask } from "@/composables/connect/connectMetaMask";
import { ethers, Signer } from "ethers";

export interface Network {
  id: number;
  name: string;
  display: string;
}

export const useAuthStore = defineStore("auth", {
  state: () => ({
    loading: false,
    address: "",
    chainId: 0,
    allowedNetworks: useLocalStorage(
      "state/allowedNetworks",
      new Array<Network>()
    ),
    status: false,
    token: "", 
    returnUrl: "/",
  }),
  getters: {
    canExecute: (state) => !state.token || !state.address || state.status,
    getAllowedNetworks: (state) => state.allowedNetworks
  },
  actions: {
    autoConnect() {
      if (this.status) {
        const metamaskconnect = window.ethereum.isConnected();
        if (!metamaskconnect) {
          connectMetamask(
            this.connect,
            this.disconnect,
            this.accountsChanged,
            this.chainChanged
          );
          return;
        }
      }
    },
    connect(address: string, chainId: number, token: string) {
      this.status = true;
      this.address = address;
      this.chainId = chainId;
      this.token = token;

      if (chainId !== 137) {
        alert("Please switch to Polygon network");
        this.disconnect();
        return;
      }

      router.push("/sale");
    },
    disconnect(): void {
      this.status = false;
      this.address = "";
      this.chainId = 0;
      this.token = "";

      router.push("/");
    },
    accountsChanged(accounts: any): void {
      if (accounts.length > 0) {
        this.address = accounts[0];
      }
    },
    chainChanged(chainId: number): void {
      if (chainId !== 137) {
        this.disconnect();
        return;
      }

      this.chainId = chainId;
    },
  },
});
