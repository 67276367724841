<template>
  <div class="row py-3 my-3">
      <div class="col-12 col-md-10 offset-md-5">
        <img src="../assets/logo.png" width="128">
      </div>
  </div>
  <div class="row  col-6  offset-md-3"  style="text-align: center; padding-bottom: 36px">
    <h6>
    To purchase an INTENT token, you must log in with MetaMask.
    </h6>
    <h6 style="text-align: center;">
      For more detailed instructions please <a href="https://www.intent-token.com/how-to-buy" target="_blank">click here</a>
    </h6>
  </div>
</template>
<script lang="ts" setup>
</script>
