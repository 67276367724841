import { defineStore } from "pinia";
import {  HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import axios from "axios";
import { useAuthStore } from "./authStore";
import { getBaseUrl } from "..";

export const useAppStore = defineStore("appStore", {
  state: () => ({
    kycLimit: null as KycLimit | null,
    kycReceiveLimit: 0,
    kycTotalReceived: 0,
    offerings: Array<Offering>(),
    exchangeRates: Array<ExchangeRate>(),
    deposits: Array<Deposit>(),
    displaySoldAmount: true,
    purchaseHistory: Array<PurchaseTransaction>(),
    connection: new HubConnectionBuilder()
      .withUrl(`${getBaseUrl()}/websocket/notification`)
      .configureLogging(LogLevel.Trace)
      .withAutomaticReconnect()
      .build()
  }),
  getters: {
    getDeposits: (state) => state.deposits,
    getOfferings: (state) => state.offerings,
    getExchangeRates: (state) => state.exchangeRates,
    getExchangeRateByCurrency: (state) => {
      return (currency: string) => {
        const item = state.exchangeRates.find((q) => q.currency === currency);
        return item;
      };
    },
    getCurrentOfferingRound: (state) => state.offerings.find((q) => q.isActive === true),
    getPurchaseHistory: (state) => state.purchaseHistory,
  },
  actions: {
    async openConnection() {
      const authStore = useAuthStore();
      if (!authStore.status) return;
      this.connection = new HubConnectionBuilder()
        .withUrl(`${getBaseUrl()}/websocket/notification`, {
          accessTokenFactory: () => {
            return `${authStore.token} ${authStore.address}`;
          },
        })
        .configureLogging(LogLevel.Trace)
        .withAutomaticReconnect()
        .build();
    },
    async enterKycCode(kycCode: string) {
      const authStore = useAuthStore();
      if (!authStore.canExecute) return;
      const url = `${getBaseUrl()}/api/kyclimit/${kycCode}`;
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${authStore.token} ${authStore.address}`,
          },
        }
      );

      this.kycLimit = response.data;
    },
    async fetchKycLimit() {
      const authStore = useAuthStore();
      if (!authStore.canExecute) return;

      const url = `${getBaseUrl()}/api/kyclimit`;
      const response = await axios.get<KycLimitResponse>(url, {
        headers: {
          Authorization: `Bearer ${authStore.token} ${authStore.address}`,
        },
      });

      this.kycLimit = response.data.kycLimit;
      this.kycReceiveLimit = response.data.receiveLimit;
      this.kycTotalReceived = response.data.totalReceived;

    },
    async fetchOfferings() {
      const url = `${getBaseUrl()}/api/offerings`;
      const response = await axios.get<OfferingResponse>(url);
      this.offerings = response.data.data;
      this.displaySoldAmount = response.data.displaySoldAmount;
    },
    async fetchExchangeRates() {
      const url = `${getBaseUrl()}/api/exchangeRates`;
      const response = await axios.get<ExchangeRate[]>(url);
      this.exchangeRates = response.data;
    },
    async setupDepositAddresses() {
      const authStore = useAuthStore();
      if (!authStore.canExecute) return;
      const url = `${getBaseUrl()}/api/deposit/setup`;
      await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${authStore.token} ${authStore.address}`,
          },
        }
      );
    },
    async fetchDepositAddresses() {
      const authStore = useAuthStore();
      if (!authStore.canExecute) return;
      const url = `${getBaseUrl()}/api/deposit/addresses`;
      const response = await axios.get<Deposit[]>(url, {
        headers: {
          Authorization: `Bearer ${authStore.token} ${authStore.address}`,
        },
      });

      this.deposits = response.data;
    },
    async fetchPurchaseHistory() {
      const authStore = useAuthStore();
      if (!authStore.canExecute) return;
      const url = `${getBaseUrl()}/api/purchase/history`;
      const response = await axios.get<PurchaseTransaction[]>(url, {
        headers: {
          Authorization: `Bearer ${authStore.token} ${authStore.address}`,
        },
      });

      this.purchaseHistory = response.data;
    },
    
    async subscribe() {
      await this.connection.start();
      this.connection.on(
        "update_exchangeRates",
        (exchangeRates: ExchangeRate[]) => {
          this.exchangeRates = exchangeRates;
        }
      );

      this.connection.on("update_offerings", (offerings: Offering[]) => {
        this.offerings = offerings;
      });

      this.connection.on("kyc_limit", async() => {
        await this.fetchKycLimit();
      });

      this.connection.on(
        "update_purchase_transaction",
        async () =>  {
          await this.fetchPurchaseHistory();
          await this.fetchKycLimit();
        }
      );
    },
    async disconnect() {
      await this.connection.stop();
    }
  },
});

export interface ExchangeRate {
  currency: string;
  price: number;
  updatedAt: Date;
  minimumDeposit: number;
  maximumDeposit: number;
}

export interface KycLimit {
  account: string;
  isRequired: boolean;
  isVerified: boolean;
  updatedAt: Date;
}

export interface KycLimitResponse {
  kycLimit: KycLimit;
  totalReceived: number;
  receiveLimit: number;
}

export interface OfferingResponse {
  displaySoldAmount: boolean;
  data: Array<Offering>;
}

export interface Offering {
  round: number;
  target: number;
  sold: number;
  price: number;
  symbol: string;
  startsAt: Date;
  endsAt: Date;
  isActive: boolean;
  displaySoldAmount: boolean;
}

export interface Deposit {
  id: string;
  address: string;
  currency: string;
  network: string;
  display: string;
}

export interface PurchaseTransaction {
  depositTxId: string;
  address: string;
  currency: string;
  network: string;
  amount: number;
  virtualAccountId: string;
  account: string;
  created: Date;
  withdrawalTxId: string;
  withdrawalAmount: string;
  updated: Date;
  status: number;
}
 
export enum PurchaseStatus {
  Awaiting = 0,
  Processing = 1,
  Purchased = 2,
  DepositFailed = 3,
  KycBlocked = 4,
  KytFailed = 5,
  WithdrawalFailed = 6
}
