import { ethers, Signer } from "ethers";
import Web3Token from "web3-token";

export async function connectMetamask(
  onConnect: (address: string, chainId: number, token: string) => void,
  onDisconnect: (code: any, reason: any) => void,
  onAccountsChanged: (accounts: any) => void,
  onChainChanged: (chainId: any) => void
) {
  try {
    window.provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    await window.provider.send("eth_requestAccounts", []);
    const signer = await window.provider.getSigner();

    const address = await signer.getAddress();
    const { chainId } = await window.provider.getNetwork();

    const token = await Web3Token.sign(
      async (msg: any) => await signer.signMessage(msg),
      {
        chain_id: chainId,
        statement: `By signing, I agree to the terms and conditions (https://intent-token.com/presale-tc.pdf) for the INTENT token pre-sale.`,
        expires_in: '360 days',
      }
    );

    onConnect(address, chainId, token);

    window.provider.on("disconnect", onDisconnect);
    window.provider.on("accountsChanged", onAccountsChanged);
    window.provider.on("chainChanged", (chainId: any) => {;
      onChainChanged(chainId);
    });
  } catch (error) {
    console.log(error);
  }
}
